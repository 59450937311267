

.result-row{
    margin:0 -20px 10px -20px;
    border: solid 1px #eee;
    border-radius: 5px;
    padding: 15px;
    background-color: #fefefe;

}

.form-check{
    margin:0 -20px 10px -20px;
    margin-bottom: 10px;
    border: solid 1px #eee;
    border-radius: 5px;
    padding: 15px;
    background-color: #fefefe;

}

.form-check:hover{
    background-color: #FFFFFF;
}

.card{
    box-shadow: -1px -1px 18px -2px rgba(97,97,97,0.25);
    -webkit-box-shadow: -1px -1px 18px -2px rgba(97,97,97,0.25);
    -moz-box-shadow: -1px -1px 18px -2px rgba(97,97,97,0.25);
    border-radius: 10px;
    padding: 20px;
}

.result-text{
    margin-bottom: 10px;
}

code{
    direction: ltr !important;
    text-align: left !important;
}

