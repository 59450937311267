@font-face {
  font-family: "NestleTextTF";
  src: url("../fonts/NestleTextTF-Book.ttf") format("truetype"),
    url("../fonts/NestleTextTFFARSI-BoldCnd.ttf") format("truetype"),
    url("../fonts/NestleTextTFFARSI-BookCnd.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: "NestleTextTF", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "font", source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.main-container {
  /* background-image: url("../../assets/images/background.png"); */
  background-size: 100% 100%;
  height: 100vh;
}

@media (max-width: 600px) {
  .main-container {
    /* background-image: url("../../assets/images/vericalbackground.jpg"); */
  }
}
