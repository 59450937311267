.poll-container {
  max-width: 400px;
  z-index: 10;
}

@media (max-width: 600px) {
  .poll-container {
    max-width: 80%;
  }
}
